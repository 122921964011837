* {
  margin: 0;
  font-family:  'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stop-hover {
  cursor: not-allowed; /* shows a stop-like circle with a line through it */
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Optional: If you want to hide the scrollbar for all browsers */
.scrollbar-hidden {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(138, 211, 222, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #83C4A8;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #83C4A8;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}